import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
      ]
        const allRowsHead = [
          [
            { text: vm.$t('globalTrans.to'), alignment: 'left', style: 'search' }
          ],
          [
            { text: (i18n.locale === 'bn') ? vm.$t('elearning_config.fiscal_year') + ' : ' + data.fiscal_year_bn : vm.$t('elearning_config.fiscal_year') + ' : ' + data.fiscal_year, alignment: 'left', style: 'search' }
          ],
          [
            { text: (i18n.locale === 'bn') ? vm.$t('globalTrans.organization') + ' : ' + data.org_bn : vm.$t('globalTrans.organization') + ' : ' + data.org, alignment: 'left', style: 'search' }
          ],
          [
            { text: (i18n.locale === 'bn') ? vm.$t('elearning_config.office_type') + ' : ' + data.office_type_bn : vm.$t('elearning_config.office_type') + ' : ' + data.office_type, alignment: 'left', style: 'search' }
          ],
          [
            { text: (i18n.locale === 'bn') ? vm.$t('globalTrans.office') + ' : ' + data.office_bn : vm.$t('globalTrans.office') + ' : ' + data.office, alignment: 'left', style: 'search' }
          ],
          [
            { text: (i18n.locale === 'bn') ? vm.$t('globalTrans.name') + ' : ' + data.guest_name_bn : vm.$t('globalTrans.name') + ' : ' + data.guest_name, alignment: 'left', style: 'search' }
          ],
          [
            { text: (i18n.locale === 'bn') ? vm.$t('globalTrans.mobile') + ' : ' + vm.$n(data.mobile, { useGrouping: false }) : vm.$t('globalTrans.mobile') + ' : ' + data.mobile, alignment: 'left', style: 'search' }
          ]
        ]
        pdfContent.push({
          table: {
            widths: ['100%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('elearning_config.room_type'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.floor_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.room_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.room_rent'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_accommodation.number_of_days'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_accommodation.bill_amount'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        data.accommodation_room_details.forEach((info, index) => {
          allRows.push([
            { text: (i18n.locale === 'bn') ? info.room_type_bn : info.room_type, style: 'td', alignment: 'center', bold: true },
            { text: info.floor_no, style: 'td', alignment: 'center', bold: true },
            { text: (i18n.locale === 'bn') ? info.room_bn : info.room, style: 'td', alignment: 'center', bold: true },
            { text: vm.$n(info.room_rent), style: 'td', alignment: 'center', bold: true },
            { text: vm.$n(data.no_of_days), style: 'td', alignment: 'center', bold: true },
            { text: vm.$n(info.room_rent * data.no_of_days), style: 'td', alignment: 'center', bold: true }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['17%', '17%', '16%', '16%', '17%', '17%'],
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRowsHeads = [
          // [
          //   { text: vm.$t('globalTrans.tax') + '(%): ' + data.tax, alignment: 'left', style: 'search', bold: true }
          // ],
          [
            { text: vm.$t('elearning_accommodation.service_charge') + ': ' + data.service_charge, alignment: 'left', style: 'search', bold: true }
          ],
          [
            { text: vm.$t('elearning_accommodation.other_deduction') + ': ' + data.other_deduction, alignment: 'left', style: 'search', bold: true }
          ],
          [
            { text: vm.$t('globalTrans.total') + ': ' + data.payment_amount, alignment: 'left', style: 'search', bold: true }
          ]
        ]
        pdfContent.push({
          table: {
            widths: ['100%'],
            body: allRowsHeads
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const footer = [
          [
            { text: vm.$t('globalTrans.date') + ' : ' + dateFormat(data.check_out), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('globalTrans.thank_you'), alignment: 'left', style: 'search' }
          ],
          [
            { text: 'MOA Software', alignment: 'left', style: 'search' }
          ]
        ]
        pdfContent.push({
          table: {
            widths: ['100%'],
            body: footer
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('payment')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
