<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_accommodation.bill_generate')}} {{ $t('globalTrans.search') }}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <div class="col-6">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="start_date"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.start_date')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.start_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="end_date"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.end_date')}}
                          </template>
                          <b-form-input
                              class="fromDate"
                              v-model="search.end_date"
                              :placeholder="$t('globalTrans.select_date')"
                          ></b-form-input>
                        </b-form-group>
                    </div>
                    <b-col lg="6" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('elearning_venue.bill_generate') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(name)="data">
                                        <span class="capitalize">{{ data.item.name }}</span>
                                        </template>
                                        <template v-slot:cell(check_in)="data">
                                          <span class="capitalize">{{ data.item.check_in | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(checkout_acm)="data">
                                          <span class="capitalize">{{ data.item.checkout_acm | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <a href="javascript:" class="btn_table_action table_action_status" title="" v-b-modal.modal-4 v-show="data.item.payment_status == 1" @click="edit(data.item)"><i class="fab fa-paypal"></i></a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Recipt" v-show="data.item.payment_status == 2" v-b-modal.modal-6 @click="edit(data.item)">
                                            <i class="fas fa-receipt"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 @click="edit(data.item)"><i class="fas fa-eye"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId" ref="form"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_accommodation.bill_generate') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId" ref="details"/>
        </b-modal>
        <b-modal id="modal-6" size="lg" :title="$t('elearning_venue.receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.export_pdf') }}
          </b-button>
          <Receipt :id="editItemId" :key="editItemId" ref="receipt"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billGeneratePayment, accommodationManagementToggleStatus, billGeneratePaymentUpdate } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import Store from '@/store'
import flatpickr from 'flatpickr'
import Form from './Form'
import Details from './Details'
import Receipt from './Receipt'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form,
      Receipt,
      Details
    },
    data () {
        return {
            search: {
              org_id: 0,
              room_type_id: 0,
              room_id: 0,
              guest_house_id: 0,
              start_date: '',
              end_date: ''
            },
            roomlist: [],
            item: '',
            datas: []
        }
    },
    computed: {
      roomTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        if (this.$store.state.Auth.authUser.org_id === 12) {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
        } else {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        }
      },
      guestHouseList: function () {
        return this.$store.state.TrainingElearning.commonObj.guestHouseList
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_accommodation.accommodation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_accommodation.bill_generate')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('org_pro.organization'), class: 'text-center' },
              { label: this.$t('globalTrans.name'), class: 'text-center' },
              { label: this.$t('globalTrans.mobile'), class: 'text-center' },
              { label: this.$t('elearning_accommodation.check_in'), class: 'text-center' },
              { label: this.$t('elearning_accommodation.check_out'), class: 'text-center' },
              { label: this.$t('elearning_config.guest_house_name'), class: 'text-center' },
              { label: this.$t('globalTrans.total'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'org_bn' },
              { key: 'name_bn' },
              { key: 'mobile' },
              { key: 'check_in' },
              { key: 'checkout_acm' },
              { key: 'guest_house_bn' },
              { key: 'total_amount' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'org' },
              { key: 'name' },
              { key: 'mobile' },
              { key: 'check_in' },
              { key: 'checkout_acm' },
              { key: 'guest_house' },
              { key: 'total_amount' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    watch: {
      'search.room_type_id': function (newValue) {
        this.roomlist = this.getRoomList(newValue)
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId
      })
      this.paymentStatusUpdate()
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        details (item) {
            this.item = item
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, accommodationManagementToggleStatus, item)
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, billGeneratePayment, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const guestHouseObj = this.$store.state.TrainingElearning.commonObj.guestHouseList.find(doc => doc.value === parseInt(item.guest_house_id))
            const guestHouseData = {}
            if (typeof guestHouseObj !== 'undefined') {
              guestHouseData.guest_house = guestHouseObj.text_en
              guestHouseData.guest_house_bn = guestHouseObj.text_bn
            } else {
              guestHouseData.guest_house = ''
              guestHouseData.guest_house_bn = ''
            }

             const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
            const officeTypeData = {}
            if (typeof officeTypeObj !== 'undefined') {
              officeTypeData.office_type = officeTypeObj.text_en
              officeTypeData.office_type_bn = officeTypeObj.text_bn
            } else {
              officeTypeData.office_type = ''
              officeTypeData.office_type_bn = ''
            }

            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }

            const OfficeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const OfficeData = {}
            if (typeof OfficeObj !== 'undefined') {
              OfficeData.office_name = OfficeObj.text_en
              OfficeData.office_name_bn = OfficeObj.text_bn
            } else {
              OfficeData.office_name = ''
              OfficeData.office_name_bn = ''
            }

            const foodPackageObj = this.$store.state.TrainingElearning.commonObj.foodPackageList.find(doc => doc.value === parseInt(item.food_package_id))
            const foodPackageData = {}
            if (typeof foodPackageObj !== 'undefined') {
              foodPackageData.foor_package = foodPackageObj.text_en
              foodPackageData.foor_package_bn = foodPackageObj.text_bn
            } else {
              foodPackageData.foor_package = ''
              foodPackageData.foor_package_bn = ''
            }
            const guestOrgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.guest_org_id))
            const guestOrgData = {}
            if (typeof guestOrgObj !== 'undefined') {
              guestOrgData.guest_org = guestOrgObj.text_en
              guestOrgData.guest_org_bn = guestOrgObj.text_bn
            } else {
              guestOrgData.guest_org = ''
              guestOrgData.guest_org_bn = ''
            }
            return Object.assign({}, item, orgData, guestHouseData, OfficeData, fiscalYearData, officeTypeData, foodPackageData, guestOrgData)
          })
          return listData
        },
      getRoomList (typeId) {
        const roomlist = this.$store.state.TrainingElearning.commonObj.roomList.filter(item => item.status === 1 && item.room_type_id === typeId)
        const tempData = roomlist.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      },
       async paymentStatusUpdate () {
        if (this.$route.params.status && this.$route.query.trnsId) {
          const status = this.$route.params.status
          const trnsId = this.$route.query.trnsId
          await RestApi.getData(trainingElearningServiceBaseUrl, billGeneratePaymentUpdate + status, { trnsId: trnsId }).then(response => {
            if (response.success) {
              this.$toast.success({
                title: 'Success',
                message: response.message,
                color: '#D6E09B'
              })
            } else {
              this.$toast.error({
                title: 'Error',
                message: response.message
              })
            }
          })
          this.loadData()
          this.$router.push('/training-e-learning-service/accommodation-management/bill-payment')
        } else {
          this.loadData()
        }
      },
      pdfExport () {
        this.$refs.receipt.pdfExport()
      }
    }
}
</script>
